/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: white;
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar,
.main-dashboard-section::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  --desktop: 1280px;
  --ipad: 1024px;
  --tab: 768px;
  --phones: 640px;
}

@font-face {
  font-family: "SF Pro Display Regular";
  font-style: normal;
  font-weight: 100;
  src: local("SF Pro Display Regular"),
    url("./assets/SF_PRO.woff") format("woff");
}

p,
li,
h1,
h2,
h3,
span {
  font-family: "SF Pro Display Regular", serif;
  /* color: #7C7C7C; */
}

.__side_bar_shadow {
  box-shadow: 0px 2px 4px 0px #1313130D;
}

.floating-button {
  box-shadow: 0px 0px 0px 0px 10px -5px #00000040;
}

button:disabled {
  background: #eaeaea;
  cursor: not-allowed;
}

button:disabled:hover {
  background: #eaeaea;
  cursor: not-allowed;
}

.avatar-holder {
  border-left: 2px solid #dddddd;
}

.__analytics-card-holder>.css-1y8mk30-SkeletonTheme>span {
  display: flex;
  width: 100%;
}

.__analytics-card-holder>.css-1y8mk30-SkeletonTheme {
  width: 100%;
}

.main-dashboard-section {
  /* max-width: 1172px; */
  width: 100%;
  /* border-left: 1px solid #dddddd; */
  height: 100vh;
  overflow-x: scroll;
}

.dash_board {
  background-color: rgba(19, 19, 19, 0.7);
}

.analytics-card {
  /* border: 1px solid #f1f1f1; */
  box-shadow: 0px 2px 4px 0px #1313130D;
  background-color: white;
  /* background: #E5EDFC;
    color: #004CE5 !important; */
}

.recent-sales-table {
  border-bottom: 0.9px solid #d8dae5;
}

.recent-sales-container {
  border: 0.8px solid #eaeaea;
  border-radius: 5px;
}

.button-groups button {
  border-radius: 0px 3.24px 3.24px 0px;
}

.button-groups {
  background: #ebecf2;
  border-radius: 3.2381px;
}

.table-body th,
.table-body tr {
  color: #9f9f9f;
}

table input:checked+div {
  @apply border-black;
}

table input:checked+div svg {
  @apply block;
}

.custom-tooltip {
  background-image: url("./assets/shape.svg");
  background-size: cover;
  background-position: center;
}

.hamburger>div,
.hamburger.close>div {
  width: 35px;
  height: 3px;
  background: black;
  border-radius: 12px;
}

.hamburger.close div:first-of-type {
  transform: rotate(45deg);
  margin: 0;
}

.hamburger.close div:nth-of-type(2) {
  transform: rotate(315deg);
  margin: 0;
}

/*.background_image*/

.hamburger div:nth-of-type(2) {
  margin-top: 5px;
  margin-bottom: 5px;
}

#edit_biodata input {
  border: 1px solid #e4e7eb;
}

#__remove {
  border-right: 0 !important;
}

#edit_biodata {
  height: 80vh;
  overflow: scroll;
}

.tool_tip {
  background: white;
  border-radius: 15px !important;
  padding: 20px 10px !important;
  box-shadow: 0px 6.36735px 6.36735px rgba(50, 50, 71, 0.08), 0px 6.36735px 12.7347px rgba(50, 50, 71, 0.06);
  ;
}

.tool_tip::after {
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -7px;
  background: white;
  transform: rotate(140deg);
  left: 45%;
  border-radius: 5px;
}

.md_table {
  display: revert;
}

.vertical_bar {
  height: 450px;
}

.__svg_color_red {
  fill: #ff0000 !important;
}

@media screen and (max-width: 1000px) {
  .analytics-card {
    min-width: 197px;
    width: 100%;
  }

  .__analytics-card-holder::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 600px) {

  /* .md_table{
    display: flex;
  } */
  /* table.md_table thead, table.md_table tbody{
    width: 90vw;
    overflow-x: scroll;
  } */
  /* .table-customer tr th:first-of-type {
    display: none;
  } */
  th.md_table {
    min-width: 200px;
  }

  th.md_table:first-of-type {
    min-width: 100px;
  }

  tbody {
    width: 70vw;
  }

  .table-holder {
    overflow-x: scroll;
  }

  th,
  td {
    height: 60px;
  }

  .analytics-card {
    min-width: 197px;
  }

  .__analytics-card-holder::-webkit-scrollbar {
    display: none;
  }

  .vertical_bar {
    height: 400px;
  }
}













/* css animation */
.loader,
.loader:after {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.loader {
  font-size: 0.875rem;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* new css */

.login__page {
  background: #fbfcfd;
}

.login__page .box__shadow {
  box-shadow: 0 0 10px -5px rgb(183 192 206 / 15%);
  box-shadow: 0 0 10px -5px rgb(183 192 206 / 15%);
  border: 1px solid rgba(238, 238, 238, 0);
}

.__order_shadow {
  box-shadow: 0px 2px 4px 0px #1313130D;
}

.modal_children{
  min-width: 600px;
}

.modal_children{
  min-width: 600px;
}
