.active_sales_tab {
    min-width: 90px;
}

;

.__total_holder {
    width: 300px
}

#todoCheckbox {
    visibility: hidden;
    border-radius: 50%;
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.todoLeftImportance:hover #todoCheckbox {
    visibility: visible;
}