.drug-stoc-input{
    background-color: #f8f9fb;
    border-color: #f1f4f8;
    color: rgba(16,16,16,.8);

};
.drug-stoc-input::placeholder{
    color: #afb5ba;
}
.drug-stoc-input:focus{
    outline: none;
    border-color: #d6e0ea;
    transition: 0.3s ease-in;
    background-color: #f8f9fb;
}