.drugstoc__button{
    background-color: #696ffb;
    border:none;
    outline: none;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%);
}
.drugstoc__button:focus{
    border: 1px solid #696ffb;
}
.drugstoc__button:hover{
    background-color: #444bfa;
    border: 1px solid #373ffa;
    transition: 0.3s ease-in;
}
